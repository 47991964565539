import React from "react"
import styled from "styled-components"
import Seo from "../components/SEO"
import { StaticImage } from "gatsby-plugin-image"
import paths from "../constants/paths"
import { Link } from "gatsby"

const Wrapper = styled.div`

`

const error = ({ location }) => {
  return (
    <Wrapper>
      <Seo title="Page Not Found" location={location} />
      <h2>Page Not Found</h2>
      <div className="text-center">
        <StaticImage src="../images/illustrations/Towhee-color-large.png" width={500}/>
      </div>
      <article>
        <p>The page you are trying to access does not exist. Please go to <Link to="/">our homepage</Link>.</p>
        <p>If you believe this is a mistake, please <Link to={paths.contact.url}>contact me</Link></p>
      </article>
    </Wrapper>
  )
}

export default error
